<template>
  <div class="mobileGatewayParam">
    <mHead :name="'双开场景面板'" :add="'/mobile/ProScene'" :adds="'/mobile/ProSceneParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LSS02A</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">符合86盒标准：86×86mm</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">AC200V~250V/50Hz</div>
      </div>
      <div class="text">
        <div class="left">负载控制:</div>
        <div class="right">50W×3路</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
      <div class="text">
        <div class="left">通信协议:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>

      <div class="text">
        <div class="left">工作频段:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">待机功耗:</div>
        <div class="right">＜1W</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">双开场景面板是客控系统中用途最为广泛的设备，提供了用户使用的所有场景模式功能。双开场景面板具有4个独立的按键，可对应4种自定义场景模式（文字可定制），每个按键都有可独立设定亮度的按键背光灯。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileGatewayParam {
  background-color: #fff;
  .param {
    margin: 0 0.3rem;
    padding-bottom: 0.26rem;
    border-bottom: 1px solid #333;
    .title {
      margin-top: 0.8rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0.59rem;
      }
      .left {
        width: 1.56rem;
        text-align: right;
      }
      .right {
        margin-left: 0.6rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0px 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;
      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>
